import React, { useState } from 'react';

// Style imports
import './Share.scss';

const Share = () => {
    const [state, setState] = useState({
        checked: false,
    });

    const url = typeof window !== 'undefined' ? window.location.href : '';
    const pageName = typeof window !== 'undefined' ? window.document.title : '';

    return (
        <>
            <div className="share">
                <input
                    className="share__input"
                    type="checkbox"
                    name="menu-open"
                    id="menu-open"
                    onChange={() => setState({...state, checked: !state.checked})}
                    checked={state.checked}
                />
                
                <label
                    className="share__button share__button--trigger"
                    htmlFor="menu-open"
                >
                   <span className='accessible'>Open share page links menu</span>
                </label>
                
                <a
                    className="share__button share__button--facebook"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                    target='_blank'
                    rel="noopener noreferrer"
                    tabIndex={state.checked ? 0 : -1}
                    aria-hidden={!!!state.checked}
                >
                    <span className='accessible'>Facebook</span>
                </a>

                <a
                    className="share__button share__button--twitter"
                    href={`http://twitter.com/share?text=High%20quality%20and%20user-focused%20website%20development,%20accessibility%20consultation%20and%20design%20services:%20&url=${url}`}
                    target='_blank'
                    rel="noopener noreferrer"
                    tabIndex={state.checked ? 0 : -1}
                    aria-hidden={!!!state.checked}
                >
                    <span className='accessible'>Twitter</span>
                </a>

                <a
                    className="share__button share__button--linkedin"
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${pageName}&summary=High%20quality%20and%20user-focused%20website%20development,%20accessibility%20consultation%20and%20design%20services.`}
                    target='_blank'
                    rel="noopener noreferrer"
                    tabIndex={state.checked ? 0 : -1}
                    aria-hidden={!!!state.checked}
                >
                    <span className='accessible'>Linkedin</span>
                </a>

                <a
                    className="share__button share__button--email"
                    href={`mailto:?subject=${pageName}&body=High%20quality%20and%20user-focused%20website%20development,%20accessibility%20consultation%20and%20design%20services:%20${url}`}
                    tabIndex={state.checked ? 0 : -1}
                    aria-hidden={!!!state.checked}
                >
                    <span className='accessible'>Email</span>
                </a>
            </div>
        </>
    );
};

export default Share;
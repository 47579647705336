import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Component imports
import Button from '../Button/Button.jsx';
import Share from '../Share/Share.jsx';
import Modal from '../Modal/Modal.jsx';
import ContactForm from '../ContactForm/ContactForm.jsx';
import Circle from '../Circle/Circle.jsx';

// Style imports
import './Introduction.scss';

const Introduction = ({
    heading,
    subheading,
    description,
    imageSource,
}) => {
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <section className='introduction'>
            <div className='introduction__container container'>
                <div className='introduction__content'>
                    <h1 className='introduction__heading'>
                        {heading}
                        <span className='introduction__heading--strong'>{subheading}</span>
                    </h1>

                    <p className='introduction__description'>{description}</p>

                    <div className='introduction__options'>
                        <Button
                            variant='hover-outline'
                            onClick={(event) => {
                                event.preventDefault();
                                openModal();
                            }}
                        >
                            Contact me
                            <span className='accessible'> Opens modal window</span>
                        </Button>

                        <Share id='introduction-share' />
                    </div>

                    <img className='introduction__image' src={imageSource} alt='' aria-hidden='true' />
                </div>
                
                {/* Desktop Circles */}
                <Circle
                    height={343}
                    width={343}
                    top={140}
                    left={-300}
                    opacity={0.25}
                    thickness={20}
                    desktop
                />

                <Circle
                    height={168}
                    width={168}
                    top={385}
                    left={-55}
                    opacity={0.1}
                    thickness={15}
                    desktop
                />

                {/* Mobile Circles */}
                <Circle
                    height={221}
                    width={221}
                    top={72}
                    left={-123}
                    opacity={0.25}
                    thickness={20}
                    mobile
                />

                <Circle
                    height={132}
                    width={132}
                    top={225}
                    left={20}
                    opacity={0.1}
                    thickness={15}
                    mobile
                />
            </div>
            
            {/* Desktop Circles */}
            <Circle
                height={1137}
                width={1137}
                top={-527}
                left={-181}
                opacity={0.2}
                desktop
            />

            <Circle
                height={1097}
                width={1097}
                top={-527}
                left={-181}
                opacity={0.2}
                thickness={40}
                desktop
            />

            <Circle
                height={400}
                width={400}
                top={-118}
                right={-125}
                opacity={0.1}
                thickness={40}
                desktop
            />

            {/* Mobile Circles */}
            <Circle
                height={267}
                width={267}
                top={-122}
                right={-150}
                opacity={0.1}
                thickness={40}
                mobile
            />
            
            {modalOpen && (
                <Modal
                    modalOpen={modalOpen}
                    onRequestClose={closeModal}
                    modalLabel='Contact modal'
                >
                    <ContactForm />
                </Modal>
            )}
        </section>
    );
};

Introduction.propTypes = {
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string,
    description: PropTypes.string,
    imageSource: PropTypes.string,
};

export default Introduction;
import React from "react";
import { Link, graphql } from "gatsby";

// Layout Imports
import Default from '../Default/Default';
import SEO from '../SEO/SEO';

// Component Imports
import Introduction from '../../components/shared/Introduction/Introduction';
import PageSelector from '../../components/shared/PageSelector/PageSelector';
import Circle from '../../components/shared/Circle/Circle';
import ArticleList from '../../components/shared/ArticleList/ArticleList';
import ArticleListItem from '../../components/shared/ArticleList/ArticleListItem';

// Style Imports
import './TagListing.scss';

// Asset Imports
import Me from '../../assets/images/content/me.svg';

const TagListing = ({
  pageContext,
  data,
  ...props
}) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMdx;

  return (
    <Default
      location={props.location}
      title={`Tag: ${tag}`}
      heading={`Tag: ${tag}`}
    >
      <SEO title={`Tag: ${tag}`} />

      <Introduction
        heading={`Tag: ${tag}`}
        description={`Viewing all blog posts tagged with "${tag}"`}
        imageSource={Me}
      />

      <PageSelector
        id='mobile-nav-selector'
        triggerText={`Tag: ${tag}`}
      />

      <div className='tag-listing'>
        <div className='tag-listing__container container'>
          <h2 className='tag-listing__heading'>
            {totalCount} post{totalCount === 1 ? "" : "s"} tagged with "{tag}"
          </h2>

          {edges && edges.length && (
            <ArticleList>
              {edges.map((item, index) => {
                return (
                  <ArticleListItem
                    date={item.node.frontmatter.date}
                    title={item.node.frontmatter.title}
                    description={item.node.frontmatter.description}
                    slug={item.node.frontmatter.path}
                    author={item.node.frontmatter.author}
                    key={index}
                  />
                );
              })}
            </ArticleList>
          )}

          <div className='tag-listing__button-wrapper'>
            <Link className='tag-listing__button button' to="/blog/tags">View all tags</Link>
          </div>
        </div>

        {/* Desktop Circles */}
        <Circle
          color='#7E8588'
          height={575}
          width={575}
          top={-230}
          right={-170}
          opacity={0.1}
          desktop
        />

        <Circle
          color='#7E8588'
          height={535}
          width={535}
          top={-230}
          right={-170}
          opacity={0.1}
          thickness={40}
          desktop
        />

        <Circle
          color='#7E8588'
          height={360}
          width={360}
          top={175}
          right={54}
          opacity={0.05}
          thickness={40}
          desktop
        />

        <Circle
          variant='gradient'
          height={576}
          width={576}
          bottom={-215}
          left={-195}
          desktop
        />

        {/* Mobile Circles */}
        <Circle
          color='#7E8588'
          height={282}
          width={282}
          top={-180}
          right={-140}
          opacity={0.1}
          thickness={15}
          mobile
        />

        <Circle
          color='#7E8588'
          height={252}
          width={252}
          top={-180}
          right={-140}
          opacity={0.1}
          thickness={30}
          mobile
        />

        <Circle
          color='#7E8588'
          height={235}
          width={235}
          top={40}
          right={-130}
          opacity={0.05}
          thickness={20}
          mobile
        />

        <Circle
          variant='gradient'
          height={311}
          width={311}
          bottom={-90}
          left={-177}
          mobile
        />
      </div>
    </Default>
  );
};

export default TagListing;

export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            description
            author
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
  }
`
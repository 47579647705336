/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// Style imports
import './PageSelector.scss';

const PageSelector = ({
    id,
    triggerText,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState(isOpen ? 'auto' : 0);
    const [speed, setSpeed] = useState(0);

    const content = useRef(null);

    useEffect(() => {
        if (isOpen) {
            const contentHeight = content.current.scrollHeight;

            const time = contentHeight / 1000;

            const animation = time < 0.3 ? 0.3 : time > 1 ? 1 : time;

            content.current.style.visibility = 'visible';

            setHeight(contentHeight);
            setSpeed(animation);
        }
        else {
            setHeight(0);
            
            setTimeout(() => {
                content.current.style.visibility = 'hiddesn';
            }, speed * 1000);
        }
    }, [
        isOpen,
        speed,
    ]);

    const toggleContent = (event) => {
        event.preventDefault();
        setIsOpen(!isOpen);
    };

    return (
        <nav className='page-selector' aria-label='Main mobile navigation'>
            <button
                className='page-selector__trigger'
                aria-controls={id + '-content'}
                aria-expanded={isOpen}
                onClick={toggleContent}
            >
                <span className='page-selector__trigger-subtext'>Currently viewing:</span>
                <span className='page-selector__trigger-text'>{triggerText}</span>
            </button>

            <section className='page-selector__menu'
                style={{
                    transition: `height ${speed}s ease-in-out`,
                    height: height,
                }}
                id={id + '-content'}
                aria-hidden={!isOpen}
            >
                <div className='page-selector__menu-inner' ref={content}>
                    <h3 className='accessible'>Mobile page navigation menu</h3>
                    <ul className='page-selector__list' role='menu'>
                        <li className='page-selector__list-item' role='none'>
                            <Link className='page-selector__link' to='/' role='menuitem'>Web Development</Link>
                        </li>

                        <li className='page-selector__list-item' role='none'>
                            <Link className='page-selector__link' to='/accessibility' role='menuitem'>Accessibility</Link>
                        </li>

                        <li className='page-selector__list-item' role='none'>
                            <Link className='page-selector__link' to='/design' role='menuitem'>UX/UI Web Design</Link>
                        </li>

                        <li className='page-selector__list-item' role='none'>
                            <Link className='page-selector__link' to='/blog' role='menuitem'>Blog</Link>
                        </li>
                    </ul>
                </div>
            </section>
        </nav>
    );
}

PageSelector.propTypes = {
    id: PropTypes.string.isRequired,
    triggerText: PropTypes.string.isRequired,
};

export default PageSelector;